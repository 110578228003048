var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"fieldset"},[(_vm.show.errorMessage)?[_c('h1',[_vm._v(_vm._s(_vm.show.errorMessage))])]:(_vm.event)?[_c('h3',{staticClass:"fieldset-heading"},[_vm._v(_vm._s(_vm.event.title))]),_c('b-card',{staticClass:"mb-12"},[_c('b-card-body',[_c('div',{staticClass:"d-flex justify-content-around pt-3 pb-2"},[_c('div',[_c('h6',{staticClass:"font-weight-lighter"},[_vm._v(" "+_vm._s(_vm.event.type)+" ")]),_c('h3',[_vm._v(_vm._s(_vm._f("moment")(_vm.event.startDate,"dddd, MMM Do")))]),_c('h6',[_vm._v(_vm._s(_vm.event.startTime)+" ("+_vm._s(_vm.calcDuration)+" minutes)")])]),_c('div',{staticClass:"d-flex flex-column"},[_c('b-button',{staticClass:"cal",attrs:{"href":'https://www.google.com/calendar/render?action=TEMPLATE&text=' +
                          _vm.subject +
                          '&details=' +
                          encodeURIComponent(_vm.uri) +
                          '&location='+_vm.event.location+'&dates=' +
                          _vm.calStartDate +
                          '%2F' +
                          _vm.calEndDate,"target":"_blank"}},[_vm._v("Add to Google ")]),_c('b-button',{staticClass:"mt-3 cal",on:{"click":_vm.createIcs}},[_vm._v("Add to iCal/Outlook ")])],1)])])],1),(_vm.show.form)?[_c('QuestionBase',{staticClass:"pb-0",attrs:{"errors":[..._vm.errors.email],"question":_vm.question.email,"valid":!!_vm.form.email}},[_c('InputStandardBasic',{attrs:{"args":_vm.inputArgs.email,"errors":_vm.errors.email},model:{value:(_vm.form.email),callback:function ($$v) {_vm.$set(_vm.form, "email", $$v)},expression:"form.email"}})],1),_c('QuestionBase',{staticClass:"pb-0",attrs:{"errors":[..._vm.errors.name],"question":_vm.question.name,"valid":!!_vm.form.name}},[_c('InputStandardBasic',{attrs:{"args":_vm.inputArgs.name,"errors":_vm.errors.name},model:{value:(_vm.form.name),callback:function ($$v) {_vm.$set(_vm.form, "name", $$v)},expression:"form.name"}})],1),_c('QuestionBase',{staticClass:"pb-0",attrs:{"errors":[..._vm.errors.contact_number],"question":_vm.question.contact_number,"valid":!!_vm.form.contact_number}},[_c('InputStandardBasic',{attrs:{"args":_vm.inputArgs.contact_number,"errors":_vm.errors.contact_number},model:{value:(_vm.form.contact_number),callback:function ($$v) {_vm.$set(_vm.form, "contact_number", $$v)},expression:"form.contact_number"}})],1),_c('div',{staticClass:"button-nav"},[_c('button',{staticClass:"btn btn-forwards",on:{"click":_vm.register}},[_vm._v("Register ")])])]:[_c('h5',{staticClass:"mt-12"},[_vm._v("Registration Completed")]),_c('p',[_vm._v(_vm._s(_vm.show.registrationMessage))])]]:_vm._e()],2)
}
var staticRenderFns = []

export { render, staticRenderFns }