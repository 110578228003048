<template>
  <div>
    <div class="main-content-wrap d-flex flex-column">
      <div id="top">
        <Header title="Event Registration" sub-title="Please enter your details to register..."/>
        <Introduction/>
        <b-container id="start" class="main-content questionnaire">
          <b-row>
            <b-col class="fieldset-wrapper" lg="7">
              <b-row>
                <b-col>
                  <RegistrationForm/>
                </b-col>
              </b-row>
            </b-col>
          </b-row>
        </b-container>
      </div>
    </div>
  </div>
</template>
<script>
import RegistrationForm from "./RegistrationForm";
import Header from "@/components/common/questionnaires/questionnaire/Header";
import Introduction from "@/components/common/questionnaires/questionnaire/Introduction";

export default {
  name: 'EventRegistration',
  components: {Introduction, Header, RegistrationForm},
}
</script>
