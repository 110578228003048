<template>
  <div class="fieldset">

    <template v-if="show.errorMessage">
      <h1>{{show.errorMessage}}</h1>
    </template>
    <template v-else-if="event">
      <h3 class="fieldset-heading">{{event.title}}</h3>

      <b-card class="mb-12">
          <b-card-body>
            <div class="d-flex justify-content-around pt-3 pb-2">
              <div>
                <h6 class="font-weight-lighter">
                  {{ event.type }} </h6>
                <h3>{{ event.startDate | moment("dddd, MMM Do") }}</h3>
                <h6>{{ event.startTime }} ({{calcDuration}} minutes)</h6>
              </div>
              <div class="d-flex flex-column">
                <b-button :href="
                            'https://www.google.com/calendar/render?action=TEMPLATE&text=' +
                            subject +
                            '&details=' +
                            encodeURIComponent(uri) +
                            '&location='+event.location+'&dates=' +
                            calStartDate +
                            '%2F' +
                            calEndDate
                          "
                          class="cal"
                          target="_blank">Add to Google
                </b-button>
                <b-button class="mt-3 cal"
                          @click="createIcs">Add to iCal/Outlook
                </b-button>
              </div>
            </div>
          </b-card-body>
      </b-card>


    <template v-if="show.form">

      <QuestionBase
          :errors="[...errors.email]"
          :question="question.email"
          :valid="!!form.email"
          class="pb-0">
        <InputStandardBasic v-model="form.email"
                            :args="inputArgs.email"
                            :errors="errors.email"/>
      </QuestionBase>

      <QuestionBase
          :errors="[...errors.name]"
          :question="question.name"
          :valid="!!form.name"
          class="pb-0">
        <InputStandardBasic v-model="form.name"
                            :args="inputArgs.name"
                            :errors="errors.name"/>
      </QuestionBase>

      <QuestionBase
          :errors="[...errors.contact_number]"
          :question="question.contact_number"
          :valid="!!form.contact_number"
          class="pb-0">
        <InputStandardBasic v-model="form.contact_number"
                            :args="inputArgs.contact_number"
                            :errors="errors.contact_number"/>
      </QuestionBase>


      <div class="button-nav">
        <button class="btn btn-forwards"
                @click="register"
        >Register
        </button>
      </div>

    </template>
    <template v-else>
      <h5 class="mt-12">Registration Completed</h5>
      <p>{{ show.registrationMessage }}</p>
    </template>

    </template>



  </div>

</template>

<script>
import moment from 'moment';
import {httpQuestionnaire} from "@/services";

import {toast} from '@/mixins/toast'

import InputStandardBasic from "@/components/common/other/inputs/InputStandardBasic";
import QuestionBase from "@/components/common/questionnaires/question/QuestionBase";

export default {
  name: 'RegistrationForm',
  components: {QuestionBase, InputStandardBasic},
  mixins: [toast],
  mounted() {
    if (this.$route.params.eventId) {
      console.log('Event Id', this.$route.params.eventId);
      this.fetch_event();
    }

  },
  data() {
    return {
      show: {
        errorMessage:null,
        form: true,
        login: false,
        registrationMessage: null,
      },
      event: null,
      form: {
        event: null,
        introducer: null,
        email: null,
        name:null,
        contact_number:null,
      },
      question: {
        email: {
          title: 'What is your email address?',
          subTitle: null,
          tip: null,
        },
        name: {
          title: 'What is your full name?',
          subTitle: null,
          tip: null,
        },
        contact_number: {
          title: 'What is your contact number?',
          subTitle: null,
          tip: null,
        },
      },
      inputArgs: {
        email: {
          placeholder: 'e.g. myemail@me.com',
          inputClass: 'field-75',
          label: 'Email'
        },
        name: {
          placeholder: 'e.g. John Smith',
          inputClass: 'field-75',
          label: 'Full Name'
        },
        contact_number: {
          placeholder: 'e.g. 07980 123456',
          inputClass: 'field-75',
          label: 'Contact Number'
        },
      },
      errors: {
        email: [],
        name: [],
        contact_number: [],
      }
    }
  },
  computed: {
        calcDuration() {
            var start = moment(this.event.startTime, 'HH:mm a');
            var end = moment(this.event.endTime, 'HH:mm a');
            if (end.isBefore(start)) end.add(1, 'day');
            var d = moment.duration(end.diff(start)).asMinutes();
            return d;
        },
        formattedDateTime(){
          return this.event.startDate+" "+this.event.startTime;
        },
    subject() {
      if (this.event.title) {
        return this.event.title.split(" ").
          join("+");
      }
      return "";
    },
    uri() {
      if(this.event.videoLink){
        return "<a href='" + this.event.videoLink + "'>Click here to join</a>";
      }
      return "";
    },
    calStartDate() {
      return moment(this.event.startDate + " " + this.event.startTime).
        format("YYYYMMDDTHHmm");
    },
    calEndDate() {
      return moment(this.event.startDate + " " + this.event.endTime).
        format("YYYYMMDDTHHmm");
    },

  },
  methods: {
    fetch_event(){
      httpQuestionnaire.get('event_registration', {params:{eventId:this.$route.params.eventId}}).then(
          (response) => {
            console.log(response.data)
            this.event = response.data;
            this.form.event = this.event.id;
            this.form.introducer = this.event.introducer;
          }
      ).catch(
          error => {
            if ('code' in error.response.data) {
              this.show.errorMessage=error.response.data.code;
            } else {
               this.handleErrors(error.response.data)
            }
          }
      )
    },
    createIcs() {
      var summary = this.event.title;
      var description = (this.event.description ? this.event.description : '') + (this.event.videoLink?' Click here to join; ' + this.event.videoLink:'');
      var test = "BEGIN:VCALENDAR\n" + "PRODID:Calendar\n" + "VERSION:2.0\n" + "BEGIN:VEVENT\n" + "UID:0@default\n" + "CLASS:PUBLIC\n" + "DESCRIPTION:" + description + "\n" + "DTSTAMP;VALUE=DATE-TIME:20210413T171616\n" + "DTSTART;VALUE=DATE-TIME:" + moment(this.event.startDate + " " + this.event.startTime).format("YYYYMMDDTHHmmss") + "\n" + "DTEND;VALUE=DATE-TIME:" + moment(this.event.startDate + " " + this.event.endTime).format("YYYYMMDDTHHmmss") + "\n" + "LOCATION:"+ this.event.location +"\n" + "SUMMARY;LANGUAGE=en-us:" + summary + "\n" + "TRANSP:TRANSPARENT\n" + "END:VEVENT\n" + "END:VCALENDAR";

      window.open("data:text/calendar;charset=utf8," + escape(test));
    },

    register() {
      this.clearErrors()
      httpQuestionnaire.post('event_registration', this.form).then(
          () => {
            this.show.form = false
            this.show.registrationMessage = 'Success, your registration is now completed.'
          }
      ).catch(
          error => {
            if ('non_field_errors' in error.response.data) {
                this.show.form = false
                this.show.registrationMessage = 'You are already registered for this event.'
            } else {
              this.handleErrors(error.response.data)
            }
          }
      )
    },
    clearErrors() {
      let keys = Object.keys(this.errors)
      for (let i in keys) {
        this.errors[keys[i]] = []
      }
    },
    handleErrors(errors) {
      let keys = Object.keys(errors)
      for (let i in keys) {
        this.errors[keys[i]] = errors[keys[i]]
      }
    },
  }
}
</script>
